import React from 'react';

import CMNavBar from '../_CMNavBar';

function Week901FirstComponent() {
  return (
    <div className="app-container">
      <CMNavBar />
      <div className="content">
        <h1>Well Done we have created a React Component</h1>
      </div>
      ;
    </div>
  );
}

export default Week901FirstComponent;
